import Link from "next/link";
import { useEffect, useState } from "react";
import { Alert, AlertTitle, Button, Stack, Typography, Divider } from "@mui/material";
import logo from "public/drvet_logo.png";

export default function Custom404() {
  const [path, setPath] = useState();

  useEffect(() => {
    setPath(location.pathname);
  }, []);

  return (
    <Stack alignItems="center" justifyContent="center" sx={{ width: "100vw", height: "100vh" }} spacing={3}>
      <Stack
        spacing={2}
        sx={{
          animation: "hueRotateTransform 10s infinite 1s alternate ",
          "@keyframes hueRotateTransform": {
            from: {
              filter: "hue-rotate(0deg)",
            },
            to: {
              filter: "hue-rotate(360deg)",
            },
          },
        }}
      >
        <Stack
          sx={{
            width: "160px",
            height: "160px",
            backgroundImage: `url(${logo.src})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
          }}
        />
        <Button color="primary" variant="contained" sx={{ "& a": { textDecoration: "none", color: "inherit" } }}>
          <Link href="/login">返回登入頁面</Link>
        </Button>
      </Stack>
      <Alert severity="info">
        <AlertTitle>頁面不存在</AlertTitle>
        <Typography>若遇到錯誤，請將以下訊息回報給業務或開發人員</Typography>
        <Divider sx={{ my: 2 }} />
        <code>404 - Page Not Found | </code>
        {path && <code>asPath : {path}</code>}
      </Alert>
    </Stack>
  );
}
